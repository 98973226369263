html {
  scroll-behavior: smooth;
}

.link {
  text-decoration: none !important;
  transition: all 0.3s ease;
}

.link:hover {
  color: #feb64d;
}

.img-pos {
  left: 5px;
}

.timeline::before {
  width: 6px;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.mobile-btn {
  display: none;
}

.desktop-btn {
  display: block;
}

.disabled {
  opacity: 0.9;
  cursor: not-allowed;
}

@media screen and (max-width: 1169px) {
  .vertical-timeline-element-icon {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 769px) {
  .mobile-btn {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .desktop-btn {
    display: none;
  }
}

.inside-image {
  transform: translate(-50%, -50%);
  z-index: 1;
}

.glass {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  box-shadow: -1px 3px 11px -4px rgba(0, 0, 0, 0.75);
}

.roadmap-heading {
  color: #fff !important;
  margin-bottom: 10px;
  font-size: 40px !important;
}

.box {
  background: rgb(0, 0, 0, 0.6);
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%, -50%);
}

.chakra-accordion__button:focus {
  box-shadow: none !important;
}

.chakra-slide {
  top: 80px !important;
}

.home-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.home-img .box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 16%;
}

.chakra-link:focus {
  box-shadow: none !important;
}

.mint-box {
  background: rgba(0, 0, 0, 0.35);
}

.chakra-link {
  text-decoration: none !important;
}

#videoBG {
  position: absolute;

  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  #videoBG {
    display: none;
  }
  body {
    background: url('./assets/hom1.png');
    background-size: cover;
  }
}
